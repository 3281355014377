import { useQuery, UseQueryOptions } from 'vue-query';
import { Ref } from 'vue';
import { QueryFilters } from '@/types/services';
import { AxiosRequestConfig } from 'axios';
import { queryKeyHash } from '@/utils/query';
import {
  AnnotationComponent,
  GetAnnotationComponentData
} from '@/entities/annotation-component/types';
import {
  getAnnotationComponent,
  getAnnotationComponents
} from '@/entities/annotation-component/services';

export const AnnotationComponentsQueryName = 'AnnotationComponents';

export const useGetAnnotationComponentsQuery = (
  options?: UseQueryOptions<AnnotationComponent[]>,
  filters?: Ref<QueryFilters<AnnotationComponent>>,
  axiosOptions?: AxiosRequestConfig,
) =>
  useQuery(AnnotationComponentsQueryName, () => getAnnotationComponents(filters?.value, axiosOptions), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetAnnotationComponentQuery = (data: Ref<GetAnnotationComponentData>) =>
  useQuery([AnnotationComponentsQueryName, data], () => getAnnotationComponent(data.value));
