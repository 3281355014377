import { ExtractPropTypes, PropType, Ref } from 'vue';
import OpenSeadragon from 'openseadragon';
import { InspectionGroup } from '@/entities/inspection-group/types';
import { Inspection } from '@/entities/inspection/types';
import { Device } from '@/entities/device/types';
import { NonNullableFields } from '@/types/utils';
import { Annotation } from '@/entities/annotation/types';
import mitt from 'mitt';
import { CompleteLocation } from '@/core/components/data-display/OSD/osd.shared';

export const events =
  mitt<{
    'annotation-click': {
      annotation: Annotation['id'];
      inspection: Inspection['id'];
      device: Device['id'];
    };
  }>();

export const inspectionViewerCreateAnnotationFormProps = {
  completeLocation: {
    type: Object as PropType<CompleteLocation>,
    required: true,
  },
  inspectionGroup: {
    type: Number as PropType<InspectionGroup['id']>,
    required: true,
  },
  inspection: {
    type: Number as PropType<Inspection['id']>,
    required: true,
  },
  device: {
    type: Number as PropType<Device['id']>,
    required: true,
  },
};
export const inspectionViewerInjectionKey = Symbol('');

export type SetActiveAnnotationParams = { id: Annotation['id'], location: OpenSeadragon.Rect };

export type InspectionViewerInjection = {
  hoveredAnnotation: Ref<Annotation['id'] | null>;
  centerFormView: (location: OpenSeadragon.Rect) => void;
  setActiveAnnotation: ({ id, location }: SetActiveAnnotationParams) => void;
  setHoveredAnnotation: (id: Annotation['id']|null) => void;
  imageHeight: Ref<{ x: number, y: number }>;
};
export type InspectionViewerCreateAnnotationFormProps = NonNullableFields<ExtractPropTypes<typeof inspectionViewerCreateAnnotationFormProps>>;
export const inspectionViewerCreateAnnotationFormInjectionKey = Symbol('');
export type InspectionViewerCreateAnnotationFormInjection = {
  props: InspectionViewerCreateAnnotationFormProps;
  emit: (event: 'cancel') => void;
};
