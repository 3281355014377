import { QueryFilters } from '@/types/services';
import { AxiosRequestConfig } from 'axios';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'vue-query';
import {
  createAnnotationType,
  deleteAnnotationType,
  getAnnotationType,
  getAnnotationTypes,
  updateAnnotationType,
} from '@/entities/annotation-type/services';
import {
  AnnotationType,
  CreateAnnotationTypeData,
  DeleteAnnotationTypeData,
  GetAnnotationTypeData,
  UpdateAnnotationTypeData,
} from '@/entities/annotation-type/types';
import { Ref, UnwrapNestedRefs } from 'vue';
import { queryKeyHash } from '@/utils/query';

export const AnnotationTypesQueryName = 'AnnotationTypes';

export const useGetAnnotationTypesQuery = (
  options?: UseQueryOptions<AnnotationType[]>,
  filters?: Ref<QueryFilters<AnnotationType>>,
  axiosOptions?: AxiosRequestConfig,
) =>
  useQuery(AnnotationTypesQueryName, () => getAnnotationTypes(filters?.value, axiosOptions), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetAnnotationTypeQuery = (
  data: Ref<GetAnnotationTypeData>,
  options: UseQueryOptions<AnnotationType> = {}
) =>
  useQuery([AnnotationTypesQueryName, data], () => getAnnotationType(data.value), { ...options });

export const useCreateAnnotationTypeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<CreateAnnotationTypeData>) => createAnnotationType(data),
    {
      onSuccess() {
        queryClient.refetchQueries(AnnotationTypesQueryName, {active: true});
      },
    },
  );
};

export const useUpdateAnnotationTypeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<UpdateAnnotationTypeData>) => updateAnnotationType(data),
    {
      onSuccess({ id }) {
        queryClient.refetchQueries(AnnotationTypesQueryName, {active: true});
        queryClient.refetchQueries([AnnotationTypesQueryName, id], {active: true});
      },
    },
  );
};

export const useDeleteAnnotationTypeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UnwrapNestedRefs<DeleteAnnotationTypeData>) => deleteAnnotationType(data),
    {
      onSuccess() {
        queryClient.refetchQueries(AnnotationTypesQueryName, {active: true});
      },
    },
  );
};
