import { Ref } from 'vue';
import { useQuery, UseQueryOptions } from 'vue-query';
import { AnnotationHistory } from '@/entities/annotation-history/types';
import { getAnnotationsHistories } from '@/entities/annotation-history/services';
import { QueryFilters } from '@/types/services';
import { queryKeyHash } from '@/utils/query';

export const annotationHistoriesQueryName = 'annotationHistories';

export const useGetAnnotationHistoriesQuery = (
  options: UseQueryOptions<AnnotationHistory[]>,
  filters: Ref<QueryFilters<AnnotationHistory>>,
) =>
  useQuery(annotationHistoriesQueryName, () => getAnnotationsHistories(filters?.value), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters.value }),
  })
