import { api } from '@/services';
import {
  AnnotationStandard,
  GetAnnotationStandardData
} from '@/entities/annotation-standard/types';
import { QueryFilters } from '@/types/services';
import { AxiosRequestConfig } from 'axios';
import { pathWithQuery } from '@/utils/string';

const BASE_URL = '/annotation_standards';

export const getAnnotationStandard = ({ id }: GetAnnotationStandardData): Promise<AnnotationStandard> =>
  api.get(`${BASE_URL}/${id}`)

export const getAnnotationStandards = (
  filters: QueryFilters<AnnotationStandard> | undefined = undefined,
  axiosOptions: AxiosRequestConfig = {},
): Promise<AnnotationStandard[]> => api.get(pathWithQuery(BASE_URL, filters), { ...axiosOptions });
