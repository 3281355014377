import { Ref } from 'vue';
import { useQuery, UseQueryOptions } from 'vue-query';
import {
  AnnotationTypeOrganization,
  GetAnnotationTypeOrganizationData
} from '@/entities/annotation-type-organization/types';
import { QueryFilters } from '@/types/services';
import { AxiosRequestConfig } from 'axios';
import {
  getAnnotationTypeOrganization,
  getAnnotationTypeOrganizations
} from '@/entities/annotation-type-organization/services';
import { queryKeyHash } from '@/utils/query';

export const AnnotationTypeOrganizationName = 'AnnotationTypeOrganizations';

export const useGetAnnotationTypeOrganizationsQuery = (
  options?: UseQueryOptions<AnnotationTypeOrganization[]>,
  filters?: Ref<QueryFilters<AnnotationTypeOrganization>>,
  axiosOptions?: AxiosRequestConfig,
) =>
  useQuery(AnnotationTypeOrganizationName, () => getAnnotationTypeOrganizations(filters?.value, axiosOptions), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value })
  });

export const useGetAnnotationTypeOrganizationQuery = (data: Ref<GetAnnotationTypeOrganizationData>) =>
  useQuery([AnnotationTypeOrganizationName, data], () => getAnnotationTypeOrganization(data.value));
