import { ExtractPropTypes } from 'vue';

export const props = {
  error: {
    type: Boolean,
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
};

export type Props = ExtractPropTypes<typeof props>;
