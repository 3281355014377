import { api } from '@/services';
import { AnnotationComponent, GetAnnotationComponentData } from '@/entities/annotation-component/types';
import { QueryFilters } from '@/types/services';
import { AxiosRequestConfig } from 'axios';
import { pathWithQuery } from '@/utils/string';

const BASE_URL = '/annotation_components';

export const getAnnotationComponent = ({ id }: GetAnnotationComponentData): Promise<AnnotationComponent> =>
  api.get(`${BASE_URL}/${id}`);

export const getAnnotationComponents = (
  filters: QueryFilters<AnnotationComponent> | undefined = undefined,
  axiosOptions: AxiosRequestConfig = {},
): Promise<AnnotationComponent[]> => api.get(pathWithQuery(BASE_URL, filters), { ...axiosOptions });


