import { Ref } from 'vue';
import { useQuery, UseQueryOptions } from 'vue-query';
import { QueryFilters } from '@/types/services';
import { queryKeyHash } from '@/utils/query';
import {
  AnnotationStandard,
  GetAnnotationStandardData
} from '@/entities/annotation-standard/types';
import {
  getAnnotationStandard,
  getAnnotationStandards
} from '@/entities/annotation-standard/services';

export const annotationStandardQueryName = 'annotationStandard';

export const useGetAnnotationStandardsQuery = (
  options?: UseQueryOptions<AnnotationStandard[]>,
  filters?: Ref<QueryFilters<AnnotationStandard>>,
) =>
  useQuery(annotationStandardQueryName, () => getAnnotationStandards(filters?.value), {
    ...options,
    queryKeyHashFn: (key) => queryKeyHash(key, { filters: filters?.value }),
  });

export const useGetAnnotationStandardQuery = (
  data: Ref<GetAnnotationStandardData>,
  options?: UseQueryOptions<AnnotationStandard>,
) => useQuery([annotationStandardQueryName, data], () => getAnnotationStandard(data.value), options);
