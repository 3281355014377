interface DeviceZone {
  name: string;
  min: number;
  max: number;
}

export type Coordinates = {
  y: number,
  height: number
}

type ThresholdSlice = {
  index: number,
  slice: number
}

/**
 *
 * @param thresholds
 * @param coordinates
 */
function retrieveEachThreshold(thresholds: number[], coordinates: Coordinates): ThresholdSlice[] {
  const { y } = coordinates
  const height = coordinates.y + coordinates.height

  return thresholds
    .map((threshold: number, index: number) => ({ index: index + 1 , slice: threshold }))
    .filter((threshold) => y <= threshold.slice && threshold.slice <= height);
}

/**
 *
 * @param thresholds
 * @param coordinates
 */
function calculateEachSlice(thresholds: ThresholdSlice[], coordinates: Coordinates): ThresholdSlice[] {
  const { y } = coordinates;
  const height = coordinates.height + coordinates.y;

  // Calcul the minimum value
  const minSlice = {
    index: thresholds[0].index - 1,
    slice: thresholds[0].slice - y
  };

  // Calcul the maximum value
  const maxSlice = {
    index: thresholds[thresholds.length - 1].index,
    slice: height - thresholds[thresholds.length - 1].slice
  };

  const list = [minSlice]
  for ( let i = 0; i < thresholds.length; i+=1 ) {
    if (i === thresholds.length - 1 ){
      break;
    }

    list.push({
      index: thresholds[i].index,
      slice: thresholds[ i + 1 ].slice - thresholds[i].slice
    });
  }

  list.push(maxSlice);

  return list.sort((a, b) => b.slice - a.slice);
}

const defaultZones = [
  {
    name: "Zone C",
    min: 0,
    max: 20
  },
  {
    name: "Zone B",
    min: 20,
    max: 70
  },
  {
    name: "Zone A",
    min: 70,
    max: 100
  },
]

/**
 * @param coordinates
 * @param imageHeight
 */
export function retrieveZone(coordinates: Coordinates, imageHeight: number ): DeviceZone {
  const pixelsThresholds = defaultZones.reduce((acc: number[], currentValue: DeviceZone): number[] => {
    if (currentValue.max === 100) {
      return acc;
    }

    acc.push(currentValue.max * imageHeight / 100);
    return acc;
  }, []);

  const { y } = coordinates;
  const height = coordinates.y + coordinates.height;

  // Check the extreme values possible
  if (height < pixelsThresholds[0]) {
    return defaultZones[0]
  }

  if (y > pixelsThresholds[pixelsThresholds.length - 1]) {
    return defaultZones[defaultZones.length - 1];
  }

  // If it is not found, we need to check the table.
  for ( let i = 0; i < pixelsThresholds.length; i+=1 ) {
    if ( y >= pixelsThresholds[i] && height <= pixelsThresholds[i + 1] ) {
      return defaultZones[i + 1];
    }
  }

  const thresholdSlice = retrieveEachThreshold(pixelsThresholds, coordinates);
  const sortedSlice = calculateEachSlice(thresholdSlice, coordinates);

  return defaultZones[sortedSlice[0].index];
}
