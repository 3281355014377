import tokens from '@/styles/tokens';
import { rem } from 'polished';
import styled from 'vue3-styled-components';
import { Props, props } from './select.shared';

const { spacing, colors, borderRadius } = tokens;

const isDisabled = (disabled: boolean) => {
  if (!disabled) return {}

  return {
    cursor: 'not-allowed',
    backgroundColor: colors.gray.DEFAULT,
    '.nk-select-arrow' : {
      color: 'transparent',
    }
  }
}

const getTypeStyle = ({ theme }: Props) => {
  const styles = {
    default: {
      border: `1px solid ${ colors.primary.DEFAULT }`,
      backgroundColor: 'white',
      '.nk-select-arrow' : {
        color: tokens.colors.primary.DEFAULT,
      }
    },
    primary: {
      border: `2px solid ${ colors.primary.DEFAULT }`,
      backgroundColor: colors.primary.DEFAULT,
      '.nk-select-arrow' : {
        color: 'white',
      }
    },
  };

  return styles[theme];
};

export const StSelect = styled('div', props)`
  cursor: pointer;
  gap: ${ () => spacing['4'] };
  display: flex;
  padding: ${ () => spacing['2'] } ${ () => spacing['5'] };
  border-radius: ${ () => borderRadius.lg };
  min-width: ${ () => rem(223) };

  .nk-select-arrow {
    transition: transform .1s ease-in-out;
  }

  .nk-select__search-input {
    outline: none;
    flex-grow: 1;

    &::placeholder {
      color: ${ () => tokens.colors.black.DEFAULT };
    }

    &:focus {
      &::placeholder {
        opacity: .4;
      }
    }
  }

  &.is-open {
    .nk-select-arrow {
      transform: rotate(180deg);
    }
  }

  ${ () => getTypeStyle }

  ${({ isDisabled: disabled }) => isDisabled(disabled)}
`;
