import { api } from '@/services';
import {
  AnnotationTypeOrganization,
  GetAnnotationTypeOrganizationData
} from '@/entities/annotation-type-organization/types';
import { QueryFilters } from '@/types/services';
import { AxiosRequestConfig } from 'axios';
import { pathWithQuery } from '@/utils/string';

const BASE_URL = 'annotation_type_organizations';

export const getAnnotationTypeOrganization = ({ id }: GetAnnotationTypeOrganizationData): Promise<AnnotationTypeOrganization> =>
  api.get(`${BASE_URL}/${id}`);

export const getAnnotationTypeOrganizations = (
  filters: QueryFilters<AnnotationTypeOrganization> | undefined = undefined,
  axiosOptions: AxiosRequestConfig = {},
): Promise<AnnotationTypeOrganization[]> => api.get(pathWithQuery(BASE_URL, filters), { ...axiosOptions });
