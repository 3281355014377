import { api } from '@/services';

import {
  CreateInspectionGroupData,
  UpdateInspectionGroupData,
  DeleteInspectionGroupData,
  GetInspectionGroupByLastDeviceData,
  GetInspectionGroupData,
  InspectionGroup,
  InspectionGroupDetail,
  GetInspectionGroupReportData,
  GetInspectionGroupZipData,
  GetInspectionGroupUseAnnotationTypeData,
} from '@/entities/inspection-group/types';

import { QueryFilters } from '@/types/services';
import { pathWithQuery, pathInspectionGroupQuery } from '@/utils/string';

export const getInspectionGroup = ({ id }: GetInspectionGroupData): Promise<InspectionGroup> => api.get(`/inspection_groups/${ id }`);

export const getInspectionGroups = (
  filters: QueryFilters<InspectionGroup> | undefined,
): Promise<InspectionGroup[]> => api.get(pathWithQuery('/inspection_groups', filters));

export const getInspectionGroupsDetails = (filters: QueryFilters<InspectionGroupDetail>): Promise<InspectionGroupDetail[]> => api.get(pathInspectionGroupQuery('/inspection_groups/details-by-device-group', filters), {
  headers: {
    'Content-Type': 'application/vnd.api+json',
    'Accept': 'application/vnd.api+json',
  },
});

export const getInspectionGroupsByLastDeviceInspection = ({ inspection }: GetInspectionGroupByLastDeviceData): Promise<InspectionGroup[]> =>
  api.get(`/inspection_groups/by-last-device-inspection?inspection=${ inspection }`);

export const getInspectionGroupReport = (data: GetInspectionGroupReportData): Promise<string> =>
  api.get(`/inspection_groups/${ data.id }/report`, {
    params: {
      devices: data.devices?.join(','),
      severities: data.severities?.join(','),
      types: data.types?.join(','),
      corporate: data.corporate,
      annexes: data.annexes,
      lang: data.lang
    },
  });

export const getInspectionGroupZip = (data: GetInspectionGroupZipData): Promise<string> =>
  api.get(`/inspection_groups/${ data.id }/zip`, {
    params: {
      annexes: data.annexes,
    },
  });

export const getInspectionGroupUseAnnotationType = (data: GetInspectionGroupData): Promise<GetInspectionGroupUseAnnotationTypeData[]> =>
  api.get(`/inspection_groups/${data.id}/annotation_types`)

export const createInspectionGroup = (data: CreateInspectionGroupData): Promise<InspectionGroup> =>
  api.post('/inspection_groups', data);

export const updateInspectionGroup = (data: UpdateInspectionGroupData): Promise<InspectionGroup> =>
  api.put(`/inspection_groups/${ data.id }`, data);

export const deleteInspectionGroup = (data: DeleteInspectionGroupData): Promise<InspectionGroup> =>
  api.delete(`/inspection_groups/${ data.id }`);

